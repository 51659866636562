import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

import { useGlobalDispatchContext } from "../utilities/context"
import useWindowType from "../utilities/useWindowType"

import { Img } from "./objects"
import FadeUp from "./fade-in-up"

const Box = styled.main`
  position: relative;
`

const Cover = styled.figure`
  position: absolute;
  top: 14.144vw;
  left: 0;
  width: 25.994vw;
  height: 20.147vw;
  z-index: 1;
  overflow: hidden;
  background: ${props => props.theme.black};

  @media (max-width: 767px) {
    width: 100%;
    position: static;
    height: 55vw;
    margin-top: 40vw;
  }
`

export default ({ children, photo }) => {
  const windowType = useWindowType()
  const dispatch = useGlobalDispatchContext()

  const [page, pageInView] = useInView()
  const [cover, coverInView] = useInView()

  useEffect(() => {
    if (coverInView) {
      dispatch({
        type: "toggle_logo_view",
        logoView: "visible",
      })

      dispatch({
        type: "toggle_menu_view",
        menuView: "visible",
      })
    }

    if (pageInView) {
      dispatch({
        type: "toggle_logo_theme",
        logoTheme: "color",
      })

      dispatch({
        type: "offset_logo",
        logoPosition: "edge",
      })
    }
  })

  return (
    <Box ref={page} className={windowType}>
      <Cover ref={cover}>
        <FadeUp delay={0.5}>
          <Img src={photo} alt="" />
        </FadeUp>
      </Cover>

      {children}
    </Box>
  )
}
