import React from "react"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"

import Meta from "../utilities/seo"

import { PageSectionWrap, PageTitle, Story } from "../components/objects"
import Page from "../components/page"
import { PageBkg } from "../components/objects"

import Photo from "../assets/images/content/shutterstock_431594938.jpg"

export default ({ data }) => {
  let node = data.prismic.allPrivacy_policys.edges[0].node

  return (
    <>
      <Meta title="Switch40 - UK Staffing Agency" />
      <Page photo={Photo}>
        <PageBkg>
          <PageSectionWrap bkg="gold">
            <PageTitle dark>Privacy policy</PageTitle>

            <Story className="privacy-policy">
              <RichText render={node.policy} />
            </Story>
          </PageSectionWrap>
        </PageBkg>
      </Page>
    </>
  )
}

export const query = graphql`
  {
    prismic {
      allPrivacy_policys {
        edges {
          node {
            policy
          }
        }
      }
    }
  }
`
